import React from 'react'
import { useContextMenu } from 'react-contexify'

import { useSortableData } from './sortableData.jsx'
import ItemImage from './ItemImage.jsx'
import CollectionContextMenu from './CollectionContextMenu.jsx'
import ItemRarenessCell from './ItemRarenessCell.jsx'
import CollectionTableHeaderCell from './CollectionTableHeaderCell.jsx'

import './CollectionTable.css'

const MENU_ID = 'ItemContextMenu'

const TradeSuggestionsTable = ({
    itemType, allItems, toGive, toReceive, memberExtra, memberMissing,
    otherInfo, setStatusText, rareness, minDisplay, solicitHelp, pageType,
}) => {
    console.info("Rendering TraceCenterTable", pageType)

    const [ toGiveItems, toGiveRequestSort, toGiveSortConfig ] = useSortableData(
        /*
         * this allItems is specific to TradeSuggestions and not indexed
         * but pagetype
         */
        toGive.map(id => allItems[id]),
        itemType,
        memberExtra,
        null,
        rareness,
        null,
        {'key': 'extra', 'direction': 'descending'},
    )

    const [
        toReceiveItems, toReceiveRequestSort, toReceiveSortConfig
    ] = useSortableData(
        /*
         * this allItems is specific to TradeSuggestions and not indexed
         * but pagetype
         */
        toReceive.map(id => allItems[id]),
        itemType,
        memberMissing,
        null,
        rareness,
        {'key': 'need', 'direction': 'descending'},
    )

    const { show } = useContextMenu({
        id: MENU_ID,
    });

    const displayVal = (min) => {
        return min ? "none" : ""
    }

    const row = (pInfo, direction, num) => {
        let id = pInfo['id']
        return (
        <tr key={id} className={`${itemType}row`}>
            <td className='year' style={{display: displayVal(minDisplay)}}>
                <a name={id} className='itemlink' href={`#${itemType}${id}`} id={`${itemType}${id}`}>{ pInfo['year'] || 'unknown' }</a>
            </td>
            <td className='ptype' style={{display: displayVal(minDisplay)}}>{ pInfo['type'] }</td>
            <td className='color' style={{display: displayVal(minDisplay)}}>{ pInfo['color'] }</td>
            <td className='front' style={{display: displayVal(minDisplay)}}>{ pInfo['front'] }</td>
            <td className='back' style={{display: displayVal(minDisplay)}}>{ pInfo['back'] }</td>
            <td className='note' style={{display: displayVal(minDisplay)}}>{ pInfo['note'] }</td>
            <ItemImage
                variety={ pInfo['variety'] }
                numImages={ pInfo['num_images'] }
                id={ id }
                linkify={ true }
                solicitHelp={ solicitHelp }
                className={`image ${direction}`}
                {...{itemType}}
            />
            <ItemRarenessCell
                rareness={rareness[id]}
                max="8"
            />
            <td className={`center ${direction}`}>{ num }</td>
        </tr>)
    }

    const getRows = (items, nums, minDisplay) => {
        return items.map(item => {
            return row(item, "", nums[item['id']], minDisplay)
        })
    }

    const getTableHeaders = (direction, requestSort, sortConfig) => {
        let common_headers = ["year", "type", "color", "front", "back"]
        let hdrs = common_headers.map(hdr => {
            return <CollectionTableHeaderCell style={{display: displayVal(minDisplay)}} key={hdr+direction} field={hdr} {...{requestSort, sortConfig}} />
        })
        hdrs.push(
            <CollectionTableHeaderCell style={{display: displayVal(minDisplay)}} field="detail" sortby="note" {...{requestSort, sortConfig}} />,
            <CollectionTableHeaderCell field="picture" nosort={true} {...{requestSort, sortConfig}} />,
            <CollectionTableHeaderCell field="rare?" {...{requestSort, sortConfig}} />,
            <CollectionTableHeaderCell field={direction} {...{requestSort, sortConfig}} />,
        )
        return <tr>{hdrs}</tr>
    }

    return (
        <>
        <CollectionContextMenu id={MENU_ID} {...{itemType, setStatusText}} />
        <div className="table-hscroll-container" onContextMenu={(e) => show({event: e})}>
            <table>
                <thead>
                    <tr>
                        <th width="50%">FOR THEM</th>
                        <td width="4px"></td>
                        <th width="50%">FOR YOU</th>
                    </tr>
                    <tr>
                        <td className="center">Your extras that { otherInfo.displayName } needs, ordered by how many you have.</td>
                        <td></td>
                        <td className="center">{ otherInfo.displayName }&lsquo;s extras that you need, ordered by how many you need.</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{verticalAlign: "top"}}>
                            <table className="items">
                            <thead>{
                                getTableHeaders(
                                    'extra',
                                    toGiveRequestSort,
                                    toGiveSortConfig
                                )
                            }</thead>
                            <tbody>
                                {getRows(toGiveItems, memberExtra)}
                            </tbody>
                            </table>
                        </td>
                        <td></td>
                        <td style={{verticalAlign: "top"}}>
                            <table className="items">
                            <thead>{
                                getTableHeaders(
                                    'need',
                                    toReceiveRequestSort,
                                    toReceiveSortConfig
                                )
                            }</thead>
                            <tbody>
                                {getRows(toReceiveItems, memberMissing)}
                            </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </>
    )
}

export default TradeSuggestionsTable
